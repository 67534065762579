<template>
	<div ref="child">
		<div class="mx-0 pa-3">
			<FieldGroup
					v-model="new_val"
					:fields="fields"
					:path="path"
					:mode="mode"
					split="2"
					:pevents="pevents"
					@saved="refresh_children++"
					@loaded="e => $emit('loaded', $refs.child)"
					@newItemCreated="(e) => $emit('newItemCreated', e)"
			/>
		</div>
		<div class="mx-0" justify="center" v-if="fields['status'].choices.length > 0 && (mode === 'edition' || mode === 'readonly') && fields['status'].choices.filter(e => e.start === true).length > 0">
			<v-tabs
					background-color="transparent"
					next-icon="mdi-arrow-right-bold-box-outline"
					prev-icon="mdi-arrow-left-bold-box-outline"
					show-arrows
					v-model="status_tab"
			>
				<v-tabs-slider></v-tabs-slider>
				<v-tab
						v-for="(start, j) in fields['status'].choices.filter(e => e.start === true)"
						:key="j"
				>
					<div class="paragraph">{{ start.display_name }}</div>
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="status_tab" mandatory class="transparent">
				<v-tab-item
						v-for="(start, j) in fields['status'].choices.filter(e => e.start === true)"
						:key="j"
				>
					<StatusGraph
							v-if="start"
							:start="start"
							:status="fields['status']"
							:itemStatus="new_val[fieldName]"
							:refresh="refresh_children * (status_tab + 1)"
					/>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<script>
	export default {
		name: "StatusField",
		props: ['value', 'fields', 'fieldName', 'path', 'oldVal', 'mode', 'pevents'],
		components: {
			Field: () => import('@/components/Field.vue'),
			StatusGraph: () => import('@/components/ui/StatusGraph.vue'),
			FieldGroup: () => import('@/components/FieldGroup.vue')
		},
		data() {
			return {
				status_tab: null,
				refresh_children: 1
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value || []
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
